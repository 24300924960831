import React from "react"
import _ from "lodash"
import classNames from "classnames"
import {ImgMainVisual} from "../Image"
import Slider from "react-slick"

export default ({items, loading}) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const setSlider = () => {
    if (!loading) {
      return <Slider {...settings}>
        {_.map(items, (item, key) => (
          _.get(item, "linkUrl") === "" ? (
            <a key={key}>
              <ImgMainVisual item={item}/>
            </a>
          ) : (
            <a href={_.get(item, "linkUrl")} target={_.get(item, "linkBlank") === "1" ? "_blank" : "_self"} key={key}>
              <ImgMainVisual item={item}/>
            </a>
          )
        ))}
      </Slider>
    }
  }

  return (
    <div className={classNames("main-visual", {loading: loading})} style={{marginTop: "30px"}}>
      {setSlider()}
    </div>
  )
}
