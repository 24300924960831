import React from "react"
import _ from "lodash"
import {ImgBanner} from "../Image"

export default ({item}) => (
  <div>
    <a href={_.get(item, "linkUrl")} target={_.get(item, "linkBlank") === "1" ? "_blank" : "_self"}>
      <ImgBanner item={item}/>
    </a>
  </div>
)