import React from "react"
import _ from "lodash"
import classNames from "classnames"
import BannerItem from "../BannerItem"
import style from "./bannerlist.module.css"

export default ({items, loading}) => (
  <div className={classNames({loading: loading}, style.list, items.length > 3 ? style.itemWrap : null)}>
    {_.map(items, item => <BannerItem item={item} key={item.id}/>)}
  </div>
)